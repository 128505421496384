import { computed } from '@vue/composition-api'
import { chain, map, each } from 'lodash'
import { BusinessResultDetailListHeaders } from '@/utils/constants/table-headers'

export default ({ incomeDetailList, spendingDetailList }) => {
  const items = computed(() => {
    return chain([...incomeDetailList.value, ...spendingDetailList.value])
      .groupBy('time')
      .map((pair, _key) => {
        const o = { ...pair[0], ...pair[1] }
        const income = Number(o.cashAmount) + Number(o.cardAmount) + Number(o.balanceReceivableAmount) + Number(o.benefitAmount)
        const spending = Number(o.castEarningAmount) + Number(o.waiterEarningAmount) + Number(o.allianceEarningAmount) + Number(o.expenseAmount)
        const profit = income - spending

        return {
          ...o,
          income,
          spending,
          profit,
        }
      })
      .value()
  })

  const buildXlsxData = () => {
    return map(items.value, o => {
      const data = {}

      each(BusinessResultDetailListHeaders, h => {
        data[h.text] = Number.isNaN(Number(o[h.value]))
          ? o[h.value]
          : Number(o[h.value])
      })

      return data
    })
  }

  return {
    items,
    buildXlsxData,
  }
}
