import { chain, each, get } from 'lodash'
import useDateFormat from '@/views/composable/useDateFormat'
import useValidation from '@/views/composable/useValidation'
import useCurrentData from '@/views/composable/useCurrentData'

export default tables => {
  const { isValidDateTimeAttributes } = useValidation()
  const { dateTime } = useDateFormat()
  const { currentClub } = useCurrentData()

  const headers = [
    { text: '卓', value: 'meta.tableName' },
    { text: '客数', value: 'attributes.customerCount' },
    { text: '顧客タグ', value: 'customerTags', sortable: false },
    {
      text: `${currentClub.value?.referralAlias || 'リファラル'}`,
      value: 'referrals',
      sortable: false,
    },
    { text: '入店', value: 'attributes.startAt' },
    { text: 'チェックアウト', value: 'attributes.receipt.data.attributes.createdAt' },
    {
      text: '伝票',
      value: 'attributes.receipt.data.id',
    },
    {
      text: '',
      value: 'actions',
      sortable: false,
      align: 'end',
    },
  ]

  const buildXlsxData = () => {
    return chain(tables.value)
      .map(o => {
        const data = {}
        each(headers, h => {
          if (h.value === 'actions') return

          if (h.value === 'customerTags') {
            data[h.text] = o.attributes?.customerTableTaggings?.data?.map(ctt => ctt.attributes.customerTag.data.attributes.name)?.join(', ')

            return
          }

          if (h.value === 'referrals') {
            data[h.text] = o.attributes?.referringHistories?.data?.map(rh => {
              return `[${rh.attributes.referral.data.attributes.name}]${rh.attributes.user.data.attributes.name}`
            })?.join(', ')

            return
          }

          if (isValidDateTimeAttributes(h.value)) {
            const dateTimeValue = get(o, h.value)

            data[h.text] = dateTimeValue ? dateTime(dateTimeValue) : null

            return
          }

          data[h.text] = get(o, h.value)
        })

        return data
      })
      .value()
  }

  return {
    headers,
    buildXlsxData,
  }
}
