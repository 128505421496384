import { map, times, flatMap } from 'lodash'
import { randomColor } from 'randomcolor'

export default () => {
  const generateRandomColorCodeArray = len => {
    const huesByPriorityOrder = [
      '#9155FD', // primary
      '#0d6efd', // info
      '#56CA00', // success
      '#FFB400', // warning
      '#FF4C51', // error
      'purple',
      'blue',
      'green',
      'yellow',
      'pink',
    ]
    const cycle = (array, count) => {
      const output = []

      times(count, i => output.push(array[i % array.length]))

      return output
    }

    return flatMap(cycle(huesByPriorityOrder, len), hue => {
      return randomColor({ hue, count: 1 })
    })
  }

  const hex2Rgb = hex => {
    // eslint-disable-next-line no-param-reassign
    if (hex.slice(0, 1) === '#') hex = hex.slice(1)
    // eslint-disable-next-line no-param-reassign
    if (hex.length === 3) hex = hex.slice(0, 1) + hex.slice(0, 1) + hex.slice(1, 2) + hex.slice(1, 2) + hex.slice(2, 3) + hex.slice(2, 3)

    return map([hex.slice(0, 2), hex.slice(2, 4), hex.slice(4, 6)], str => parseInt(str, 16))
  }

  const chartColors = {
    primaryColorShade: '#836AF9',
    yellowColor: '#ffe800',
    successColorShade: '#28dac6',
    warningColorShade: '#ffe802',
    warningLightColor: '#FDAC34',
    infoColorShade: '#299AFF',
    greyColor: '#4F5D70',
    blueColor: '#2c9aff',
    blueLightColor: '#84D0FF',
    greyLightColor: '#EDF1F4',
    tooltipShadow: 'rgba(0, 0, 0, 0.25)',
    lineChartPrimary: '#666ee8',
    lineChartDanger: '#ff4961',
    labelColor: '#6e6b7b',
    grid_line_color: 'rgba(200, 200, 200, 0.2)',
  }

  return {
    // methods
    generateRandomColorCodeArray,
    hex2Rgb,
    chartColors,
  }
}
