import {
  ref,
  computed,
} from '@vue/composition-api'
import {
  update,
  every,
} from 'lodash'
import useValidation from '@/views/composable/useValidation'

export default () => {
  const referringHistories = ref([])
  const referringHistoriesTemp = ref([])

  const { isValidId } = useValidation()

  const isValidToSubmit = computed(() => {
    return every(
      referringHistoriesTemp.value,
      referringHistory => isValidId(referringHistory.referralId) && isValidId(referringHistory.userId),
    )
  })

  const buildReferringHistory = ({
    id = null,
    userId = null,
    referralId = null,
    _destroy = false,
  }) => {
    return {
      id,
      userId,
      referralId,
      _destroy,
    }
  }

  const addReferringHistoryTemp = ({
    id = null,
    userId = null,
    referralId = null,
    _destroy = false,
  }) => {
    referringHistoriesTemp.value.push(buildReferringHistory({
      id,
      userId,
      referralId,
      _destroy,
    }))

    return true
  }

  const removeReferringHistoryTemp = index => {
    const removeTargetReferringHistoryTemp = referringHistoriesTemp.value.splice(index, 1)[0]

    // NOTE: 保存済みのものを削除の場合_destroyをtrueにして元いた場所に返す
    if (removeTargetReferringHistoryTemp.id) {
      // eslint-disable-next-line no-underscore-dangle
      removeTargetReferringHistoryTemp._destroy = true
      referringHistoriesTemp.value.splice(index, 0, removeTargetReferringHistoryTemp)
    }

    return true
  }

  const updateReferringHistoryTemp = ({ key, val, index }) => {
    update(referringHistoriesTemp.value[index], key, () => val)

    return true
  }

  const resetReferringHistoriesTemp = () => {
    referringHistoriesTemp.value = []

    return true
  }

  return {
    referringHistories,
    referringHistoriesTemp,
    isValidToSubmit,
    buildReferringHistory,
    addReferringHistoryTemp,
    removeReferringHistoryTemp,
    updateReferringHistoryTemp,
    resetReferringHistoriesTemp,
  }
}
