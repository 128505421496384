import { ref } from '@vue/composition-api'
import { get } from 'lodash'

export default () => {
  const isEditing = ref(false)
  const editTarget = ref({})

  const cancelEdit = () => {
    isEditing.value = false
    editTarget.value = {}
  }

  const edit = (attributes = {}) => {
    cancelEdit()

    isEditing.value = true
    editTarget.value = attributes
  }

  const isEditingHere = (itemId, editTargetPath) => {
    return isEditing.value
      && get(editTarget.value, editTargetPath)
      && Number(itemId) === Number(editTarget.value.id)
  }

  return {
    // data
    isEditing,
    editTarget,

    // methods
    cancelEdit,
    edit,
    isEditingHere,
  }
}
