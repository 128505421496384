/* eslint-disable no-use-before-define */
import { computed } from '@vue/composition-api'
import {
  groupBy,
  map,
  chain,
  compact,
  keys,
  includes,
  reduce,
  mapValues,
  each,
} from 'lodash'

export default ({
  userSalesSummaries,
  userContributionRatioSummaries,
  userVendingSummaries,
  userReferringSummaries,
  userVendingPointSummaries,
  userSalesSummaryHeaders,
  userContributionRatioSummaryHeaders,
  userVendingSummaryHeaders,
  userReferringSummaryHeaders,
  userVendingPointSummaryHeaders,
  role,
}) => {
  const _allHeaders = computed(() => {
    return {
      userSalesSummaries: userSalesSummaryHeaders.value,
      userContributionRatioSummaries: userContributionRatioSummaryHeaders.value,
      userVendingSummaries: userVendingSummaryHeaders.value,
      userReferringSummaries: userReferringSummaryHeaders.value,
      userVendingPointSummaries: userVendingPointSummaryHeaders.value,
    }
  })

  const _userSalesSummariesByRole = computed(() => {
    return {
      cast: [],
      waiter: [],
      alliance: [],
      ...groupBy(userSalesSummaries.value, 'userRole'),
    }
  })
  const _userContributionRatioSummariesByRole = computed(() => {
    return {
      cast: [],
      waiter: [],
      alliance: [],
      ...groupBy(userContributionRatioSummaries.value, 'userRole'),
    }
  })
  const _userVendingSummariesByRole = computed(() => {
    return {
      cast: [],
      waiter: [],
      alliance: [],
      ...groupBy(userVendingSummaries.value, 'userRole'),
    }
  })
  const _userReferringSummariesByRole = computed(() => {
    return {
      cast: [],
      waiter: [],
      alliance: [],
      ...groupBy(userReferringSummaries.value, 'userRole'),
    }
  })
  const _userVendingPointSummariesByRole = computed(() => {
    return {
      cast: [],
      waiter: [],
      alliance: [],
      ...groupBy(userVendingPointSummaries.value, 'userRole'),
    }
  })
  const items = computed(() => {
    return chain([
      ..._userSalesSummariesByRole.value[role.value],
      ..._userContributionRatioSummariesByRole.value[role.value],
      ..._userVendingSummariesByRole.value[role.value],
      ..._userReferringSummariesByRole.value[role.value],
      ..._userVendingPointSummariesByRole.value[role.value],
    ])
      .groupBy('userId')
      .flatMap((userItems, _userId) => {
        return reduce((userItems || []), (acc, cur) => {
          return { ...acc, ...cur }
        }, {})
      })
      .value()
  })

  const userSalesSummaryHeadersByRole = computed(() => {
    return {
      cast: getHeaders({ results: _userSalesSummariesByRole.value.cast, key: 'userSalesSummaries' }),
      waiter: getHeaders({ results: _userSalesSummariesByRole.value.waiter, key: 'userSalesSummaries' }),
      alliance: getHeaders({ results: _userSalesSummariesByRole.value.alliance, key: 'userSalesSummaries' }),
    }
  })
  const userContributionRatioSummaryHeadersByRole = computed(() => {
    return {
      cast: getHeaders({ results: _userContributionRatioSummariesByRole.value.cast, key: 'userContributionRatioSummaries' }),
      waiter: getHeaders({ results: _userContributionRatioSummariesByRole.value.waiter, key: 'userContributionRatioSummaries' }),
      alliance: getHeaders({ results: _userContributionRatioSummariesByRole.value.alliance, key: 'userContributionRatioSummaries' }),
    }
  })
  const userVendingSummaryHeadersByRole = computed(() => {
    return {
      cast: getHeaders({ results: _userVendingSummariesByRole.value.cast, key: 'userVendingSummaries' }),
      waiter: getHeaders({ results: _userVendingSummariesByRole.value.waiter, key: 'userVendingSummaries' }),
      alliance: getHeaders({ results: _userVendingSummariesByRole.value.alliance, key: 'userVendingSummaries' }),
    }
  })
  const userReferringSummaryHeadersByRole = computed(() => {
    return {
      cast: getHeaders({ results: _userReferringSummariesByRole.value.cast, key: 'userReferringSummaries' }),
      waiter: getHeaders({ results: _userReferringSummariesByRole.value.waiter, key: 'userReferringSummaries' }),
      alliance: getHeaders({ results: _userReferringSummariesByRole.value.alliance, key: 'userReferringSummaries' }),
    }
  })
  const userVendingPointSummaryHeadersByRole = computed(() => {
    return {
      cast: getHeaders({ results: _userVendingPointSummariesByRole.value.cast, key: 'userVendingPointSummaries' }),
      waiter: getHeaders({ results: _userVendingPointSummariesByRole.value.waiter, key: 'userVendingPointSummaries' }),
      alliance: getHeaders({ results: _userVendingPointSummariesByRole.value.alliance, key: 'userVendingPointSummaries' }),
    }
  })

  const headers = computed(() => {
    const rawHeaders = compact([
      {
        value: 'userName',
        text: '対象者',
        width: '120px',
        divider: true,
        class: 'fixed-cell',
        cellClass: 'fixed-cell',
      },
      ...map(userSalesSummaryHeadersByRole.value[role.value], o => {
        return {
          ...o,
          unit: '¥',
        }
      }),
      ...map(userContributionRatioSummaryHeadersByRole.value[role.value], o => {
        return {
          ...o,
          unit: '%',
        }
      }),
      ...userVendingSummaryHeadersByRole.value[role.value],
      ...userReferringSummaryHeadersByRole.value[role.value],
      ...map(userVendingPointSummaryHeadersByRole.value[role.value], o => {
        return {
          ...o,
          unit: 'pt',
        }
      }),
    ])

    return map(rawHeaders, (o, idx) => { return { ...o, divider: idx !== rawHeaders.length - 1 } })
  })

  const accRow = computed(() => {
    return {
      ...chain(headers.value)
        .map(header => {
          const obj = {}
          const sum = items.value.reduce((acc, cur) => {
            // eslint-disable-next-line no-param-reassign
            acc += Number(cur[header.value]) ? Number(cur[header.value]) : 0

            return acc
          }, 0)
          obj[header.text] = sum || null

          return obj
        })
        .reduce((acc, cur) => {
          // eslint-disable-next-line no-param-reassign
          acc = { ...acc, ...cur }

          return acc
        }, {})
        .value(),
      対象者: '合計',
    }
  })

  function getHeaders({ results = [], key }) {
    const values = chain(results)
      .flatMap(result => keys(result))
      .uniq()
      .reject(columnName => {
        return includes(
          [
            'userId',
            'userName',
            'userRole',
          ],
          columnName,
        )
      })
      .value()

    return (_allHeaders.value[key] || []).filter(o => values.includes(o.value))
  }

  const buildXlsxData = () => {
    return [
      mapValues(accRow.value, v => { return Number(v) ? Math.abs(v) : v }),
      ...map(items.value, item => {
        const obj = {}
        each(headers.value, header => {
          obj[header.text] = Number(item[header.value]) ? Math.abs(Number(item[header.value])) : item[header.value]
        })

        return obj
      }),
    ]
  }

  return {
    items,
    userSalesSummaryHeadersByRole,
    userContributionRatioSummaryHeadersByRole,
    userVendingSummaryHeadersByRole,
    userReferringSummaryHeadersByRole,
    userVendingPointSummaryHeadersByRole,
    headers,
    accRow,
    buildXlsxData,
  }
}
