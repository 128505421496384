import { computed } from '@vue/composition-api'
import { chain, each } from 'lodash'
import { ExpenseAndBenefitListHeaders } from '@/utils/constants/table-headers'

export default ({ expenses, benefits }) => {
  const items = computed(() => {
    return [
      ...chain(expenses.value)
        .map(o => {
          const {
            expensePrimaryLabelName: primaryLabelName,
            expenseSecondaryLabelName: secondaryLabelName,
            withCashRegisterTransaction: isCash,
            expenseOnDate: businessDate,
          } = o.meta
          const { amount } = o.attributes

          // eslint-disable-next-line object-curly-newline
          return { uid: `expense-${o.id}`, groupName: '諸費', primaryLabelName, secondaryLabelName, amount, isCash, businessDate }
        })
        .value(),
      ...chain(benefits.value)
        .map(o => {
          const {
            benefitPrimaryLabelName: primaryLabelName,
            benefitSecondaryLabelName: secondaryLabelName,
            withCashRegisterTransaction: isCash,
            benefitOnDate: businessDate,
          } = o.meta
          const { amount } = o.attributes

          // eslint-disable-next-line object-curly-newline
          return { uid: `benefit-${o.id}`, groupName: '諸入', primaryLabelName, secondaryLabelName, amount, isCash, businessDate }
        })
        .value(),
    ]
  })

  const buildXlsxData = () => {
    return chain(items.value)
      .sortBy('businessDate')
      .map(o => {
        const data = {}

        each(ExpenseAndBenefitListHeaders, h => data[h.text] = o[h.value])

        data['タイプ'] = o.groupName

        return data
      })
      .value()
  }

  return {
    items,
    buildXlsxData,
  }
}
