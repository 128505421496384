import { computed } from '@vue/composition-api'
import { chain, each, get } from 'lodash'

export default ({
  earningSummaries,
  wageEarningHeaders,
  rebateSystemUnitEarningHeaders,
  orphanEarningHeaders,
  paySystemUnitEarningHeaders,
}) => {
  const headers = computed(() => {
    return [
      {
        value: 'date',
        text: '日付',
        width: '80px',
        divider: true,
        class: 'fixed-cell',
        cellClass: 'fixed-cell',
      },
      { value: 'additionsEarningAmount', text: '総支給額', width: '120px' },
      { value: 'deductionsEarningAmount', text: '総控除額', width: '120px' },
      { value: 'afterhandEarningAmount', text: '差引支給額', width: '120px' },
      { value: 'beforehandEarningAmount', text: '日払', width: '120px' },
      {
        value: 'remainingEarningAmount',
        text: '残給',
        width: '120px',
        divider: true,
      },
      ...wageEarningHeaders.value,
      ...rebateSystemUnitEarningHeaders.value,
      ...orphanEarningHeaders.value,
      ...paySystemUnitEarningHeaders.value,
    ]
  })

  const headerColSpanSize = computed(() => {
    return {
      staticSize: 6,
      wageSize: wageEarningHeaders.value.length,
      rebateSystemUnit: rebateSystemUnitEarningHeaders.value.length,
      paySystemUnit: paySystemUnitEarningHeaders.value.length,
      orphan: orphanEarningHeaders.value.length,
    }
  })

  const buildXlsxData = () => {
    return chain(earningSummaries.value)
      .map(o => {
        const data = {}

        each(headers.value, h => {
          const val = get(o, h.value)
          data[h.text] = Number.isNaN(Number(val)) ? val : Number(val)
        })

        return data
      })
      .value()
  }

  return {
    headers,
    headerColSpanSize,
    buildXlsxData,
  }
}
