import { computed } from '@vue/composition-api'
import {
  groupBy,
  chain,
  compact,
  keys,
  includes,
  find,
  mapValues,
  map,
  each,
  times,
} from 'lodash'

export default ({
  earningSummaries,
  rebateSystemUnits,
  paySystemUnits,
  role,
  isDaily,
}) => {
  const _earningSummariesByRole = computed(() => {
    return {
      cast: [],
      waiter: [],
      alliance: [],
      ...groupBy(earningSummaries.value, 'userRole'),
    }
  })

  const _earningSummaryDynamicColumnNamesByRole = computed(() => {
    const getNames = earnings => {
      return chain(earnings)
        .flatMap(earning => keys(earning))
        .uniq()
        .reject(columnName => {
          return includes(
            [
              'userId',
              'userName',
              'userRole',
              'additionsEarningAmount',
              'deductionsEarningAmount',
              'beforehandEarningAmount',
              'afterhandEarningAmount',
              'remainingEarningAmount',
              'wageEarningAmount',
              'workingDays',
              'workingDuration',
            ],
            columnName,
          )
        })
        .value()
    }

    return {
      cast: getNames(_earningSummariesByRole.value.cast) || [],
      waiter: getNames(_earningSummariesByRole.value.waiter) || [],
      alliance: getNames(_earningSummariesByRole.value.alliance) || [],
    }
  })

  const rebateSystemUnitEarningHeaders = computed(() => {
    const headers = chain(_earningSummaryDynamicColumnNamesByRole.value[role.value])
      .filter(columnName => /^rebateSystemUnit\d{1,}$/.test(columnName))
      .uniq()
      .map(columnName => {
        const id = columnName.match(/rebateSystemUnit(\d{1,})/)[1]

        return {
          value: columnName,
          text: find(rebateSystemUnits.value, o => +o.id === +id)?.attributes?.name,
          divider: false,
        }
      })
      .value()

    if (headers.length > 0) headers.at(-1).divider = true

    return headers
  })

  const paySystemUnitEarningHeaders = computed(() => {
    // NOTE: ケツに入れるのでdividerいらない
    return chain(_earningSummaryDynamicColumnNamesByRole.value[role.value])
      .filter(columnName => /^paySystemUnit\d{1,}$/.test(columnName))
      .uniq()
      .map(columnName => {
        const id = columnName.match(/paySystemUnit(\d{1,})/)[1]

        return {
          value: columnName,
          text: find(paySystemUnits.value, o => +o.id === +id)?.attributes?.name,
        }
      })
      .value()
  })

  const orphanEarningHeaders = computed(() => {
    const headers = chain(_earningSummaryDynamicColumnNamesByRole.value[role.value])
      .reject(columnName => /^rebateSystemUnit\d{1,}$/.test(columnName) || /^paySystemUnit\d{1,}$/.test(columnName))
      .uniq()
      .map(columnName => {
        return {
          value: columnName,
          text: columnName,
          divider: false,
        }
      })
      .value()

    if (headers.length > 0) headers.at(-1).divider = true

    return headers
  })

  const wageEarningHeaders = computed(() => {
    if (role.value === 'alliance') return []

    return compact([
      isDaily.value
        ? null
        : { value: 'workingDays', text: '勤務日数', width: '20px' },
      { value: 'workingDuration', text: '勤務時間', width: '60px' },
      { value: 'avgHourlyWage', text: '平均時給', width: '60px' },
      {
        value: 'wageEarningAmount',
        text: '支給額',
        width: '120px',
        divider: true,
      },
    ])
  })

  const headers = computed(() => {
    return compact([
      {
        value: 'userName',
        text: '対象者',
        width: '120px',
        divider: true,
        class: 'fixed-cell',
        cellClass: 'fixed-cell',
      },
      { value: 'additionsEarningAmount', text: '総支給額', width: '120px' },
      { value: 'deductionsEarningAmount', text: '総控除額', width: '120px' },
      { value: 'afterhandEarningAmount', text: '差引支給額', width: '120px' },
      { value: 'beforehandEarningAmount', text: '日払', width: '120px' },
      {
        value: 'remainingEarningAmount',
        text: '残給',
        width: '120px',
        divider: true,
      },
      ...wageEarningHeaders.value,
      ...rebateSystemUnitEarningHeaders.value,
      ...orphanEarningHeaders.value,
      ...paySystemUnitEarningHeaders.value,
    ])
  })

  const headerColSpanSize = computed(() => {
    return {
      staticSize: 6,
      wageSize: wageEarningHeaders.value.length,
      rebateSystemUnit: rebateSystemUnitEarningHeaders.value.length,
      paySystemUnit: paySystemUnitEarningHeaders.value.length,
      orphan: orphanEarningHeaders.value.length,
    }
  })

  const items = computed(() => {
    return _earningSummariesByRole.value[role.value]
  })

  const accRow = computed(() => {
    return {
      ...chain(headers.value)
        .map(header => {
          const obj = {}
          const sum = items.value.reduce((acc, cur) => {
            // eslint-disable-next-line no-param-reassign
            acc += Number(cur[header.value]) ? Number(cur[header.value]) : 0

            return acc
          }, 0)
          obj[header.text] = sum || null

          return obj
        })
        .reduce((acc, cur) => {
          // eslint-disable-next-line no-param-reassign
          acc = { ...acc, ...cur }

          return acc
        }, {})
        .value(),
      対象者: '合計',
    }
  })

  const buildXlsxData = () => {
    return [
      {}, // NOTE: headerの概要(あとでaddする)
      mapValues(accRow.value, v => { return Number(v) ? Math.abs(v) : v }),
      ...map(items.value, item => {
        const obj = {}
        each(headers.value, header => {
          obj[header.text] = Number(item[header.value]) ? Math.abs(Number(item[header.value])) : item[header.value]
        })

        return obj
      }),
    ]
  }

  const buildXlsxSecondaryHeaders = () => {
    return [
      ...(headerColSpanSize.value.staticSize ? times(headerColSpanSize.value.staticSize, () => null) : []),
      ...(headerColSpanSize.value.wageSize ? ['基本給', ...times(headerColSpanSize.value.wageSize - 1, () => null)] : []),
      ...(headerColSpanSize.value.rebateSystemUnit ? ['バック', ...times(headerColSpanSize.value.rebateSystemUnit - 1, () => null)] : []),
      ...(headerColSpanSize.value.orphan ? ['その他/調整', ...times(headerColSpanSize.value.orphan - 1, () => null)] : []),
      ...(headerColSpanSize.value.paySystemUnit ? ['変動給', ...times(headerColSpanSize.value.paySystemUnit - 1, () => null)] : []),
    ]
  }

  return {
    rebateSystemUnitEarningHeaders,
    paySystemUnitEarningHeaders,
    orphanEarningHeaders,
    wageEarningHeaders,
    headers,
    headerColSpanSize,
    items,
    accRow,
    buildXlsxData,
    buildXlsxSecondaryHeaders,
  }
}
