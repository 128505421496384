import { chain, each, get } from 'lodash'
import useValidation from '@/views/composable/useValidation'
import useDateFormat from '@/views/composable/useDateFormat'
import { V2VendingHistoryListHeaders } from '@/utils/constants/table-headers'

export default vendingHistories => {
  const { isValidDateTimeAttributes } = useValidation()
  const { dateTime } = useDateFormat()

  const buildXlsxData = () => {
    return chain(vendingHistories.value)
      .map(o => {
        const data = {}
        each(V2VendingHistoryListHeaders, h => {
          if (h.value === 'meta.recipients') {
            data[h.text] = o.meta?.recipients
              ?.map(r => {
                let text = r.name
                if (r.ratio !== 1.0) text += `(${r.ratio * 100}%)`

                return text
              })
              ?.join(', ')

            return
          }

          if (h.value === 'meta.contributors') {
            data[h.text] = o.meta.contributors
              ?.map(r => {
                let text = r.name
                if (r.ratio !== 1.0) text += `(${r.ratio * 100}%)`

                return text
              })
              ?.join(', ')

            return
          }

          if (h.value === 'meta.vendibleTagNames') {
            data[h.text] = o.meta.vendibleTagNames?.join(', ')

            return
          }

          if (isValidDateTimeAttributes(h.value)) {
            const dateTimeValue = get(o, h.value)

            data[h.text] = dateTimeValue ? dateTime(dateTimeValue) : null

            return
          }

          data[h.text] = get(o, h.value)
        })

        return data
      })
      .value()
  }

  return {
    buildXlsxData,
  }
}
