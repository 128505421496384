import { chain, each, get } from 'lodash'
import useValidation from '@/views/composable/useValidation'
import useDateFormat from '@/views/composable/useDateFormat'
import { VendingHistoryListHeaders } from '@/utils/constants/table-headers'

export default vendingHistories => {
  const { isValidDateTimeAttributes } = useValidation()
  const { dateTime } = useDateFormat()

  const buildXlsxData = () => {
    return chain(vendingHistories.value)
      .map(o => {
        const data = {}
        each(VendingHistoryListHeaders, h => {
          if (h.value === 'recipients') {
            data[h.text] = o.attributes?.vendibleRecipients?.data
              ?.map(r => {
                let text = r.attributes.recipient.data.attributes.name
                if (r.attributes.ratio !== 1.0) {
                  text += `(${r.attributes.ratio * 100}%)`
                }

                return text
              })
              ?.join(', ')

            return
          }

          if (h.value === 'contributors') {
            data[h.text] = o.attributes?.vendingContributions?.data
              ?.map(r => {
                let text = r.attributes.contributor.data.attributes.name
                if (r.attributes.ratio !== 1.0) {
                  text += `(${r.attributes.ratio * 100}%)`
                }

                return text
              })
              ?.join(', ')

            return
          }

          if (h.value === 'meta.vendibleTagNames') {
            data[h.text] = o.meta.vendibleTagNames?.join(', ')

            return
          }

          if (isValidDateTimeAttributes(h.value)) {
            const dateTimeValue = get(o, h.value)

            data[h.text] = dateTimeValue ? dateTime(dateTimeValue) : null

            return
          }

          data[h.text] = get(o, h.value)
        })

        return data
      })
      .value()
  }

  return {
    buildXlsxData,
  }
}
