import { ref, computed } from '@vue/composition-api'
import {
  filter,
  chain,
  isEqual,
  map,
  each,
} from 'lodash'

export default ({ tableResultOverviews, dynamicTableResultOverviewHeaders }) => {
  const _vendibleTagHeaders = computed(() => filter(dynamicTableResultOverviewHeaders.value, o => o.value.startsWith('vendibleTag')))
  const _referralHeaders = computed(() => filter(dynamicTableResultOverviewHeaders.value, o => o.value.startsWith('referral')))
  const _customerTagHeaders = computed(() => filter(dynamicTableResultOverviewHeaders.value, o => o.value.startsWith('customerTag')))

  const headers = computed(() => reComputeTrigger => {
    // eslint-disable-next-line no-unused-expressions
    reComputeTrigger.value

    const isAllSameHallName = chain(tableResultOverviews.value).map('hallName').every(isEqual).value

    return chain([
      { value: 'number', text: '' },
      { value: 'in', text: 'IN' },
      { value: 'out', text: 'OUT' },
      { value: 'duration', text: '時間' },
      isAllSameHallName ? null : { value: 'hallName', text: 'ホール' },
      { value: 'tableNumber', text: '卓番' },
      { value: 'customerCount', text: '人数' },
      { value: 'checkAmount', text: '会計' },
      { value: 'userSalesAmount', text: '売上計上' },
      { value: 'receiptId', text: '伝票' },
      ..._customerTagHeaders.value,
      ...map(_vendibleTagHeaders.value, o => { return { ...o, cellClass: 'table-result-overviews-vendible-tag-cells' } }),
      ..._referralHeaders.value,
    ])
      .compact()
      .map(o => { return { ...o, divider: true } })
      .value()
  })

  const accRow = computed(() => {
    const ignoreValues = ['number', 'in', 'out', 'customerCount', 'hallName', 'tableNumber', 'checkAmount', 'userSalesAmount', 'receiptId']

    return {
      ...chain(headers.value(ref(true)))
        .map(header => {
          const obj = {}
          obj[header.text] = null

          if (ignoreValues.includes(header.value)) return obj

          const sum = tableResultOverviews.value.reduce((acc, cur) => {
            // eslint-disable-next-line no-param-reassign
            acc += Number(cur[header.value]) ? Number(cur[header.value]) : 0

            return acc
          }, 0)

          obj[header.text] = sum || null

          return obj
        })
        .reduce((acc, cur) => {
          // eslint-disable-next-line no-param-reassign
          acc = { ...acc, ...cur }

          return acc
        }, {})
        .value(),
      '': '合計',
    }
  })

  const buildXlsxData = () => {
    const downloadHeaders = [...headers.value(ref(true))]

    return chain(tableResultOverviews.value)
      .map(o => {
        const data = {}
        each(downloadHeaders, h => data[h.text] = o[h.value])

        return data
      })
      .value()
  }

  return {
    headers,
    accRow,
    buildXlsxData,
  }
}
