import { computed } from '@vue/composition-api'
import {
  map,
  chain,
  compact,
  reduce,
  each,
  get,
} from 'lodash'

export default ({
  userSalesSummaries,
  userContributionRatioSummaries,
  userVendingSummaries,
  userReferringSummaries,
  userVendingPointSummaries,
  userSalesSummaryHeaders,
  userContributionRatioSummaryHeaders,
  userVendingSummaryHeaders,
  userReferringSummaryHeaders,
  userVendingPointSummaryHeaders,
}) => {
  const items = computed(() => {
    return chain([
      ...userSalesSummaries.value,
      ...userContributionRatioSummaries.value,
      ...userVendingSummaries.value,
      ...userReferringSummaries.value,
      ...userVendingPointSummaries.value,
    ])
      .groupBy('date')
      .flatMap((userItems, _date) => {
        return reduce((userItems || []), (acc, cur) => {
          return { ...acc, ...cur }
        }, {})
      })
      .value()
  })

  const headers = computed(() => {
    const rawHeaders = compact([
      {
        value: 'date',
        text: '日付',
        width: '80px',
        divider: true,
        class: 'fixed-cell',
        cellClass: 'fixed-cell',
      },
      ...userSalesSummaryHeaders.value,
      ...userContributionRatioSummaryHeaders.value,
      ...userVendingSummaryHeaders.value,
      ...userReferringSummaryHeaders.value,
      ...userVendingPointSummaryHeaders.value,
    ])

    return map(rawHeaders, (o, idx) => { return { ...o, divider: idx !== rawHeaders.length - 1 } })
  })

  const buildXlsxData = () => {
    return chain(items.value)
      .map(o => {
        const data = {}

        each(headers.value, h => {
          const val = get(o, h.value)
          data[h.text] = Number.isNaN(Number(val)) ? val : Number(val)
        })

        return data
      })
      .value()
  }

  return {
    items,
    headers,
    buildXlsxData,
  }
}
