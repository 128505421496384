import { chain, each, get } from 'lodash'
import useDateFormat from '@/views/composable/useDateFormat'
import useValidation from '@/views/composable/useValidation'
import { ReferringHistoriesHeaders } from '@/utils/constants/table-headers'

export default referringHistories => {
  const { isValidDateTimeAttributes } = useValidation()
  const { dateTime } = useDateFormat()

  const buildXlsxData = () => {
    return chain(referringHistories.value)
      .map(o => {
        const data = {}
        each(ReferringHistoriesHeaders, h => {
          if (h.value === 'actions') return
          if (isValidDateTimeAttributes(h.value)) {
            const dateTimeValue = get(o, h.value)

            data[h.text] = dateTimeValue ? dateTime(dateTimeValue) : null

            return
          }
          data[h.text] = get(o, h.value)
        })

        return data
      })
      .value()
  }

  return {
    buildXlsxData,
  }
}
