import { chain, each, get } from 'lodash'
import useDateFormat from '@/views/composable/useDateFormat'
import useValidation from '@/views/composable/useValidation'
import { BalanceReceivableHeaders } from '@/utils/constants/table-headers'

export default balanceReceivables => {
  const { isValidDateTimeAttributes } = useValidation()
  const { dateTime } = useDateFormat()

  const buildXlsxData = () => {
    return chain(balanceReceivables.value)
      .map(o => {
        const data = {}
        each(BalanceReceivableHeaders, h => {
          if (h.BalanceReceivableHeaders === 'meta.paid') return
          if (h.value === 'diff') return

          if (isValidDateTimeAttributes(h.value)) {
            const dateTimeValue = get(o, h.value)

            data[h.text] = dateTimeValue ? dateTime(dateTimeValue) : null

            return
          }

          data[h.text] = get(o, h.value)
        })

        return data
      })
      .value()
  }

  return {
    buildXlsxData,
  }
}
