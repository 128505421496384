import { chain, each, get } from 'lodash'
import useDateFormat from '@/views/composable/useDateFormat'
import useValidation from '@/views/composable/useValidation'
import { TimecardHeaders } from '@/utils/constants/table-headers'

export default timecards => {
  const { isValidDateTimeAttributes } = useValidation()
  const { dateTime } = useDateFormat()

  const buildXlsxData = () => {
    return chain(timecards.value)
      .map(o => {
        const data = {}
        each(TimecardHeaders, h => {
          if (h.value === 'actions') return

          if (h.value === 'attributes.breakCards.data') {
            data[h.text] = o.attributes?.breakTimecards?.data?.map(bt => {
              // NOTE: dateTimeにnullが入ると1970-01-01T00:00:00.000Zになるのでnullの場合を考慮
              const startAt = bt.attributes.breakTimeStartAt ? dateTime(bt.attributes.breakTimeStartAt) : null
              const endAt = bt.attributes.breakTimeEndAt ? dateTime(bt.attributes.breakTimeEndAt) : null

              return `${startAt} ~ ${endAt}`
            })?.join(', ')

            return
          }

          if (isValidDateTimeAttributes(h.value)) {
            const dateTimeValue = get(o, h.value)

            data[h.text] = dateTimeValue ? dateTime(dateTimeValue) : null

            return
          }

          data[h.text] = get(o, h.value)
        })

        return data
      })
      .value()
  }

  return {
    buildXlsxData,
  }
}
